<template>
    <b-card>
      <g-form @submit="print">
        <b-row>
             <b-col md="4" >
                    <g-picker
                      :value.sync="selectedItem.fromDate"
                      label-text="fromDate"
                      name="fromDate"
                    />
                  </b-col>
                   <b-col md="4" >
                    <g-picker
                      :value.sync="selectedItem.toDate"
                      label-text="toDate"
                      name="toDate"
                    />
                  </b-col>
          <b-col md="4">
            <b-form-group>
              <g-field
                :value.sync="selectedItem.storeId"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="store"
                field="select"
                name="defaultStoreId"
                :options="stores"
                :label="isRight ? 'arabicName' : 'englishName'"
              />
            </b-form-group>
          </b-col>
           </b-row>
           <b-row>
            <!-- category  -->
           <b-col md="4" >
               <b-form-group>
              <g-field
                :value.sync="selectedItem.categoryID"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="category"
                field="select"
                name="category"
                :options="categories"
                :label="isRight ? 'arabicName' : 'englishName'"
              />
            </b-form-group>
           </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col cols="12">
            <items
              :storeId="selectedItem.storeId"
              :items.sync="items"
              :categoryId="selectedItem.categoryID"
            />
            </b-col>
           </b-row>
        <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button class="mx-1" type="submit"   variant="primary">
              {{ $t('review') }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-card>
  </template>
  <script>
  import reportMixin from '@/mixin/reportMixin';
  import items from './components/items.vue';

  export default {
      components: {
        items,
    },
    props: ['id'],
     mixins: [
      reportMixin,
    ],
    data() {
      return {
        stores: [],
        categories: [],
        items: [],
        selectedItem: {
          fromDate: this.today,
          toDate: this.today,
          categoryID: null,
          itemId: null,
          storeId: null
        },
      };
    },
    computed: {},
    beforeMount() {
      this.fiscalYearStart = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.startDate : this.company.fiscalYearStart;
      this.fiscalYearEnd = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.endDate : this.company.fiscalYearEnd;
    },
    mounted() {
      this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
      this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
      this.getData();
    },
    methods: {
      checkDate() {
        if (this.selectedItem.fromDate === null) {
          this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
        }
        if (this.selectedItem.toDate === null) {
          this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
        }
      },
      getData() {
        this.get({ url: 'Aggregates/Items' }).then((data) => {
          if (this.currentBranch.stores && this.currentBranch.stores.length > 0) {
          const branchStores = this.currentBranch.stores.map(
            (item) => item.storeId
          );
          this.stores = data.stores.filter(
            (item) => branchStores.indexOf(item.id) > -1
          );
        } else {
          this.stores = [];
        }
          this.categories = data.itemCategories;
        });
      },
      print() {
        this.checkDate();
        if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
        if (this.items.length > 0) {
           const itemIdList = [];
          this.items.forEach(element => {
            itemIdList.push(element.id);
          });
          this.selectedItem.itemId = itemIdList.toString();
        } else {
         this.selectedItem.itemId = null;
        }
        if (this.selectedItem.storeId) {
            this.selectedItem.storeName = this.stores.filter(x => x.id === this.selectedItem.storeId)[0].arabicName;
        } else {
            this.selectedItem.storeName = null;
        }
        if (this.selectedItem.categoryID) {
            this.selectedItem.categoryName = this.categories.filter(x => x.id === this.selectedItem.categoryID)[0].arabicName;
        } else {
            this.selectedItem.categoryName = null;
        }
        if (this.selectedItem.fromDate > this.selectedItem.toDate) {
            this.doneAlert({ text: this.$t('fromDateAfterToDate'), type: 'warning' });
            return;
        }
        if (this.currentBranch.activityType === 'concrete') {
          this.printReport('ItemTransactionsConcreteDetails-ar', this.selectedItem);
        } else {
          this.printReport('ItemTransactionsDetails-ar', this.selectedItem);
        }
      },
    },
  };
  </script>
