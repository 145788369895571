<template>
    <b-card>
        <g-form @submit="print">
            <b-row>
                <b-col md="4">
                    <b-form-group>
                        <g-field
                            :value.sync="selectedItem.storeId"
                            :dir="isRight ? 'rtl' : 'ltr'"
                            label-text="store"
                            field="select"
                            name="defaultStoreId"
                            :options="stores"
                            :label="isRight ? 'arabicName' : 'englishName'"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <g-picker
                        :value.sync="selectedItem.fromDate"
                        label-text="fromDate"
                        name="fromDate"
                    />
                </b-col>
                <b-col md="4">
                    <g-picker
                        :value.sync="selectedItem.toDate"
                        label-text="toDate"
                        name="toDate"
                    />
                </b-col>
                <b-col md="2">
                    <b-form-group :label="$t('hideZeroBalances')">
                        <b-form-checkbox
                            v-model="selectedItem.hideZeroBalances"
                            class="custom-control-primary"
                        >
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <hr />
              </b-col>
              <b-col cols="12" class="d-flex justify-content-end">
                  <button type="submit" class="btn mx-1 btn-primary">{{ $t('review') }}</button>
              </b-col>
            </b-row>
            <br><br>
        </g-form>
    </b-card>
</template>

<script>

import reportMixin from '@/mixin/reportMixin';

export default {
  props: ['id'],

  mixins: [
    reportMixin,
  ],

    data() {
        return {
            stores: [],
            storeBalances: [],
            selectedItem: {
                fromDate: null,
                toDate: null,
                storeId: null,
                storeName: null,
                hideZeroBalances: false
            },
        }
    },
    beforeMount() {
    this.fiscalYearStart = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.startDate : this.company.fiscalYearStart;
    this.fiscalYearEnd = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.endDate : this.company.fiscalYearEnd;
  },
  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
    this.reports = this.menu;
    this.selectedReport = 'storeBalanceReport';
    this.getStores();
  },

  methods: {
    checkDate() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
      }
    },
    getStores() {
      this.get({ url: 'Stores' }).then((data) => {
        if (this.currentBranch.stores && this.currentBranch.stores.length > 0) {
          const branchStores = this.currentBranch.stores.map(
            (item) => item.storeId
          );
          this.stores = data.filter(
            (item) => branchStores.indexOf(item.id) > -1
          );
        } else {
          this.stores = [];
        }
      });
    },
    print() {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      if (this.selectedItem.storeId) {
        this.selectedItem.storeName = this.stores.filter(x => x.id === this.selectedItem.storeId)[0].arabicName;
      }
      if (this.selectedItem.fromDate > this.selectedItem.toDate) {
          this.doneAlert({ text: this.$t('fromDateAfterToDate'), type: 'warning' });
          return;
      }
      this.printReport('StoreBalance-ar', this.selectedItem);
      this.selectedItem.storeName = null;
    }
  }
}

</script>

<style>
.getRed {
  border: 1px solid red;
}
.getNormal {
  border: 1px solid transparent;
}
</style>
