<template>
    <b-card>
      <g-form @submit="print">
        <b-row>
              <!-- fromDate  -->
              <!-- <b-col md="4" >
                    <g-picker
                      :value.sync="selectedItem.dateFrom"
                      label-text="fromDate"
                      name="fromDate"
                    />
                  </b-col> -->
                   <b-col md="4" >
                    <g-picker
                      :value.sync="selectedItem.dateTo"
                      label-text="date"
                      name="date"
                    />
                  </b-col>
          <b-col md="4">
            <b-form-group>
              <g-field
                :value.sync="selectedItem.storeId"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="store"
                field="select"
                name="defaultStoreId"
                :options="stores"
                :label="isRight ? 'arabicName' : 'englishName'"
              />
            </b-form-group>
          </b-col>
            <!-- category  -->
           <b-col md="4" >
               <b-form-group>
              <g-field
                :value.sync="selectedItem.categoryId"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="category"
                field="select"
                name="category"
                :options="categories"
                :label="isRight ? 'arabicName' : 'englishName'"
              />
            </b-form-group>
           </b-col>
           <b-col md="2">
                <b-form-group :label="$t('hideZeroBalances')">
                    <b-form-checkbox
                        v-model="selectedItem.hideZeroBalances"
                        class="custom-control-primary"
                    >
                    </b-form-checkbox>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col cols="12">
            <items
              :storeId="selectedItem.storeId"
              :items.sync="items"
              :categoryId="selectedItem.categoryId"
            />
            </b-col>
           </b-row>
        <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button class="mx-1" type="submit"  variant="primary">
              {{ $t('review') }}
            </b-button>
            <b-button class="mx-1" @click="printStoreEvalutionWithSalesPrice()"  variant="primary">
              {{ $t('reviewStoreEvalutionWithSalesPrice') }}
            </b-button>
            <b-button class="mx-1" @click="printStoreEvalutionWithCostPrice()"  variant="primary">
              {{ $t('reviewStoreEvalutionWithCostPrice') }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-card>
  </template>
  <script>
  import reportMixin from '@/mixin/reportMixin';
  import items from './components/items.vue';

  export default {
      components: {
        items,
    },
     props: {
      type: {
        type: Object,
        default: null,
      },
    },
     mixins: [
      reportMixin,
    ],
    data() {
      return {
        stores: [],
        categories: [],
        items: [],
        selectedItem: {
           dateFrom: this.today,
          dateTo: this.today,
          categoryId: null,
          itemId: null,
          storeId: null,
          hideZeroBalances: false
        },
      };
    },
    computed: {},
    beforeMount() {
      this.fiscalYearStart = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.startDate : this.company.fiscalYearStart;
      this.fiscalYearEnd = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.endDate : this.company.fiscalYearEnd;
    },
    mounted() {
      this.selectedItem.dateFrom = this.getDate(this.fiscalYearStart)
      this.selectedItem.dateTo = this.getDate(this.fiscalYearEnd)
      this.getData();
    },
    methods: {
      getData() {
        this.get({ url: 'Aggregates/Items' }).then((data) => {
          if (this.currentBranch.stores && this.currentBranch.stores.length > 0) {
          const branchStores = this.currentBranch.stores.map(
            (item) => item.storeId
          );
          this.stores = data.stores.filter(
            (item) => branchStores.indexOf(item.id) > -1
          );
        } else {
          this.stores = [];
        }
          this.categories = data.itemCategories;
        });
      },
      print() {
        if (!this.checkForFiscalYearPerm(this.selectedItem.dateFrom, this.selectedItem.dateTo)) return;
         if (this.items.length > 0) {
           const itemIdList = [];
          this.items.forEach(element => {
            itemIdList.push(element.id);
          });
          this.selectedItem.itemId = itemIdList.toString();
        } else {
         this.selectedItem.itemId = null;
        }
        if (this.selectedItem.storeId) {
          this.selectedItem.storeName = this.stores.filter(x => x.id === this.selectedItem.storeId)[0].arabicName;
        } else {
          this.selectedItem.storeName = null;
        }
         if (this.selectedItem.categoryId) {
          this.selectedItem.categoryName = this.categories.filter(x => x.id === this.selectedItem.categoryId)[0].arabicName;
        } else {
          this.selectedItem.categoryName = null;
        }
        let repName = '';
        if (this.type.route === 'store-items-total-balance-report') repName = 'StoreItemsTotalBalance-ar';
        else repName = 'StoreEvalution-ar';
        this.printReport(repName, this.selectedItem);
      },
      printStoreEvalutionWithSalesPrice() {
        if (!this.checkForFiscalYearPerm(this.selectedItem.dateFrom, this.selectedItem.dateTo)) return;
         if (this.items.length > 0) {
           const itemIdList = [];
          this.items.forEach(element => {
            itemIdList.push(element.id);
          });
          this.selectedItem.itemId = itemIdList.toString();
        } else {
         this.selectedItem.itemId = null;
        }
        if (this.selectedItem.storeId) {
          this.selectedItem.storeName = this.stores.filter(x => x.id === this.selectedItem.storeId)[0].arabicName;
        } else {
          this.selectedItem.storeName = null;
        }
         if (this.selectedItem.categoryId) {
          this.selectedItem.categoryName = this.categories.filter(x => x.id === this.selectedItem.categoryId)[0].arabicName;
        } else {
          this.selectedItem.categoryName = null;
        }
        let repName = '';
        if (this.type.route === 'store-items-total-balance-report') repName = 'StoreItemsTotalBalance-ar';
        else repName = 'StoreEvalutionWithSalesPrice-ar';
        this.printReport(repName, this.selectedItem);
      },
      printStoreEvalutionWithCostPrice() {
        if (!this.checkForFiscalYearPerm(this.selectedItem.dateFrom, this.selectedItem.dateTo)) return;
         if (this.items.length > 0) {
           const itemIdList = [];
          this.items.forEach(element => {
            itemIdList.push(element.id);
          });
          this.selectedItem.itemId = itemIdList.toString();
        } else {
         this.selectedItem.itemId = null;
        }
        if (this.selectedItem.storeId) {
          this.selectedItem.storeName = this.stores.filter(x => x.id === this.selectedItem.storeId)[0].arabicName;
        } else {
          this.selectedItem.storeName = null;
        }
         if (this.selectedItem.categoryId) {
          this.selectedItem.categoryName = this.categories.filter(x => x.id === this.selectedItem.categoryId)[0].arabicName;
        } else {
          this.selectedItem.categoryName = null;
        }
        let repName = '';
        if (this.type.route === 'store-items-total-balance-report') repName = 'StoreItemsTotalBalance-ar';
        else repName = 'StoreEvalutionWithCostPrice-ar';
        this.printReport(repName, this.selectedItem);
      }
    },
  };
  </script>
