<template>
    <b-card>
      <b-row>
        <b-col md="4">
          <label for="fromDate" class="labelStyle">{{ $t("fromDate") }}</label>
          <div class="pickerStyle">
            <v-date-picker
              mode="dateTime"
              v-model="selectedItem.dateFrom"
              is24hr
              name="fromDate"
              :masks="{ L: 'YYYY-MM-DD' }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 pickerStyle"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
        </b-col>
        <b-col md="4">
          <label for="toDate" class="labelStyle">{{ $t("toDate") }}</label>
          <div>
            <v-date-picker
              mode="dateTime"
              v-model="selectedItem.dateTo"
              is24hr
              label-text="toDate"
              name="toDate"
              :masks="{ L: 'YYYY-MM-DD' }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 pickerStyle"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group>
            <g-field
              id="code"
              type="number"
              :value.sync="selectedItem.code"
              label-text="code"
              name="code"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.storeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="store"
              field="select"
              name="defaultStoreId"
              :options="stores"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <items :storeId="selectedItem.storeId" :items.sync="items" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button class="mx-1" variant="primary" @click="print('First')">
            {{ $t("shortageAndOverflowReport") }}
          </b-button>
          <b-button class="mx-1" variant="primary" @click="print('Second')">
            {{ $t("shortageAndOverflowReportWithSalesPrice") }}
          </b-button>
          <b-button class="mx-1" variant="primary" @click="print('Third')">
            {{ $t("shortageAndOverflowReportWithCostPrice") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </template>
  <script>
  import reportMixin from '@/mixin/reportMixin';
  import items from './components/items.vue';

  export default {
    props: {
      type: {
        type: Object,
        default: null,
      },
    },
    components: {
      items,
    },
    mixins: [
      reportMixin,
    ],
    beforeMount() {
      this.fiscalYearStart = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.startDate : this.company.fiscalYearStart;
      this.fiscalYearEnd = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.endDate : this.company.fiscalYearEnd;
    },
    mounted() {
      this.selectedItem.dateFrom = this.fiscalYearStart;
      this.selectedItem.dateTo = new Date(this.fiscalYearEnd).setHours(23, 59, 59);
      this.getItems();
    },
    data() {
      return {
        stores: [],
        items: [],
        selectedItem: {
          code: null,
          itemId: null,
          storeId: null,
          storeName: null,
          dateFrom: null,
          dateTo: null
        },
      }
    },
    methods: {
      getItems() {
        this.get({ url: 'Aggregates/Items' }).then((data) => {
          if (this.currentBranch.stores && this.currentBranch.stores.length > 0) {
            const branchStores = this.currentBranch.stores.map(
              (item) => item.storeId
            );
            this.stores = data.stores.filter(
              (item) => branchStores.indexOf(item.id) > -1
            );
          } else {
            this.stores = [];
          }
        });
      },
      print(number) {
        this.selectedItem.dateFrom = this.getDate(this.selectedItem.dateFrom, true);
        this.selectedItem.dateTo = this.getDate(this.selectedItem.dateTo, true);
        if (this.selectedItem.storeId != null) {
          this.selectedItem.storeName = this.stores.filter(x => x.id === this.selectedItem.storeId)[0].arabicName;
        } else {
          this.selectedItem.storeName = null;
        }
        if (this.items.length > 0) {
          const itemIdList = [];
          this.items.forEach(element => {
            itemIdList.push(element.id);
          });
          this.selectedItem.itemId = itemIdList.toString();
        } else {
          this.selectedItem.itemId = null;
        }
        if (this.selectedItem.dateFrom > this.selectedItem.dateTo) {
            this.doneAlert({ text: this.$t('fromDateAfterToDate'), type: 'warning' });
            return;
        }
        if (number === 'First') {
          this.printReport('ShortageAndOverflowInventoryReport-ar', this.selectedItem);
        }
        if (number === 'Second') {
          this.printReport('ShortageAndOverflowBySalesPriceInventoryReport-ar', this.selectedItem);
        }
        if (number === 'Third') {
          this.printReport('ShortageAndOverflowByCostPriceInventoryReport-ar', this.selectedItem);
        }
      }
    }
  };
  </script>
  <style>
  .pickerStyle {
    width: 100%;
    height: 3rem;
    font-weight: normal;
  }
  .labelStyle {
    font-size: inherit;
    line-height: 1.45;
  }
  </style>
