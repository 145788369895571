<template>
    <b-row>
      <b-col md="3" class="mt-4">
        <b-card>
          <ul class="list-unstyled">
            <li
              v-bind:class="{ 'btn-flat-primary': selectedReport === x.title }"
              @click="
                () => {
                  selectedReport = x.title;
                  selected = x;
                }
              "
              class="p-1 nav-item-report cursor-pointer border-bottom"
              v-for="x in reports"
              :key="x.title"
              v-permission="x.permission"
            >
              <feather-icon :icon="x.icon" size="20" class="mr-75" />
              <span>{{ $t(x.title) }}</span>
            </li>
          </ul>
        </b-card>
      </b-col>
      <b-col md="9">
        <b-card-title
          v-if="selectedReport"
          class="p-0 m-0 text-primary text-darken-5"
        >
          <b-row class="p-0">
            <b-col md="12" class="text-center">
              <span>{{ $t(selectedReport) }}</span>
            </b-col>
          </b-row>
        </b-card-title>
        <hr v-if="selectedReport" class="border-info border-darken-2" />
        <!-- 5555 -->
        <storeEvalutionReport
          :type="selected"
          v-if="
            selectedReport === 'storeEvalutionReport' ||
            selectedReport === 'StoreItemsTotalBalanceReport'
          "
        />
        <!-- 5555 -->
        <inventoryReport v-if="selectedReport === 'inventoryReport'" />
        <!-- 5555 -->
        <itemTransactionsTotalsReport
          v-if="selectedReport === 'itemTransactionsTotalsReport'"
        />
        <!-- 5555 -->
        <itemTransactionsDetailsReport
          v-if="selectedReport === 'itemTransactionsDetailsReport'"
        />
        <!-- 5555 -->
        <storeBalanceReport v-if="selectedReport === 'storeBalanceReport'" />

      </b-col>
    </b-row>
  </template>
  <script>
  import { itemsReports } from '@/libs/acl/Lookups';
  import storeEvalutionReport from '@/pages/Settings/Report/StoreEvalutionReport.vue';
  import itemTransactionsTotalsReport from '@/pages/Settings/Report/ItemTransactionsTotalsReport.vue';
  import itemTransactionsDetailsReport from '@/pages/Settings/Report/ItemTransactionsDetailsReport.vue';
  import storeBalanceReport from '@/pages/Settings/Report/StoreBalanceReport.vue'
  import reportMixin from '@/mixin/reportMixin';
  import inventoryReport from '@/pages/Settings/Report/InventoryReport.vue';

  export default {
    components: {
      storeEvalutionReport,
      itemTransactionsTotalsReport,
      itemTransactionsDetailsReport,
      storeBalanceReport,
      inventoryReport
    },
    mixins: [
      reportMixin,
    ],
    data() {
      return {
        menu: [],
        itemsReports,
        reports: [],
        selectedReport: '',
        selected: {}
      };
    },
    computed: {},
    beforeMount() {
      this.menu = this.itemsReports;
    },
    mounted() {
      this.reports = this.menu;
      this.selectedReport = 'itemTransactionsTotalsReport';
      if (this.currentBranch.activityType !== 'restaurant') {
        this.reports = this.menu.filter((i) => i.route !== 'qr-code-invoice-report');
      }
      if (this.profile.permissions.length > 0) {
        this.selectedReport = this.getSelectedReport(this.reports, this.profile.permissions);
        this.selected = this.reports.find(obj => obj.title === this.selectedReport);
      }
    },
    methods: {},
  };
  </script>
  <style scoped>
  .nav-item-report:hover {
    margin-left: 5px;
    transition: all 0.5s ease;
  }
  </style>
