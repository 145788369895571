<template>
    <b-card>
      <b-modal
        no-close-on-backdrop
        ref="search-modal"
        cancel-variant="outline-secondary"
        centered
        size="lg"
        hide-footer
      >
        <b-row>
          <b-col md="4">
            <g-field
            id="barcode"
            :value.sync="filter.barcode"
            label-text="barcode"
            v-silent-search
            name="barcode"
              @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
          />
          </b-col>
          <b-col md="4">
            <g-field
              :value.sync="filter.arabicNamePart"
              v-silent-search
              label-text="arabicName"
              name="arabicName"
              id="arabicName"
              @keydown.enter="
                (v) => {
                  refreshItems();
                }
              "
            />
          </b-col>
          <b-col md="4">
            <g-field
              :value.sync="filter.englishNamePart"
              v-silent-search
              label-text="englishName"
              name="arabicName"
              @keydown.enter="
                (v) => {
                  refreshItems();
                }
              "
            />
          </b-col>
          <b-col cols="12" class="medium-window">
            <b-table
              ref="itemstable"
              :items="itemsSearchProvider"
              :fields="tableColumns"
              :is-busy="isTableBusy"
              primary-key="id"
              show-empty
              striped
              hover
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              :sort-direction="sortDirection"
              :filter="searchQuery"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :empty-text="$t('noMatchingRecordsFound')"
            >
              <template #cell(mainUnitId)="{ item }">
                <g-field
                  :value.sync="item.unitId"
                  field="select"
                  item-id="unitId"
                  :options="item.units"
                  :clearable="false"
                  :label="isRight ? 'unitArabicName' : 'unitEnglishName'"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  :style="{ width: '150px' }"
                />
              </template>
              <template #head(actions)>
              <span></span>
            </template>
              <!-- Column: Actions -->
              <template #cell(actions)="{ item }">
                  <b-button
                data-action-type="save"
                  size="sm"
                  variant="outline-primary"
                  @click="
                    (val) => {
                      triggerAdding(item);
                    }
                  "
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </template>
            </b-table>
               <b-row class="pt-1">
        <!-- Pagination -->
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          :label="$t('entries')"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            @change="(v) => {
              if (perPage === 'الكل') {
                perPage = 10000
              }
              }"
            :options="perPageOptions"
          />
        </b-form-group>
        <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
      </b-card-body>
      </b-row>
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end py-1 pb-2">
            <b-button class="mx-1" variant="secondary" @click="closeModal">
              {{ $t('Close') }}
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
      <div class="my-2">
        <!-- Table Top -->
        <b-row  align-h="between">
           <b-col
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              @click="openModal"
            >
              {{ $t('addItem') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="g-table"
        :items="items"
        :fields="columns"
        primary-key="barcode"
        show-empty
        hover
        small
        responsive
        foot-clone
        stickyColumn
        :per-page="items.length"
        :current-page="currentPage2"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :sort-direction="sortDirection"
        class="mb-0"
        :empty-text="$t('noMatchingRecordsFound')"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
          />
        </template>
        <template #head(actions)>
          <span></span>
        </template>
        <template #cell(mainUnitId)="{ item }">
          <g-field
            field="select"
            item-id="unitId"
            placeholder="unit"
            class="select-size-sm my-0"
            :disabled="!!item.transactionId"
            :value.sync="item.unitId"
            :options="item.units"
            :clearable="false"
            :label="isRight ? 'unitArabicName' : 'unitEnglishName'"
            :dir="isRight ? 'rtl' : 'ltr'"
          />
        </template>
        <template #cell(arabicName)="{ item }">
          <span style="'{min-width: 150px;}"> {{ item.arabicName  }}</span>
        </template>
        <template #cell(actions)="{ item }">
          <div class="text-nowrap">
             <b-button
              variant="flat-danger"
              size="sm"
              class="btn-icon"
             @click="() => {
               const index = addedItems.indexOf(item);
                if (index > -1) {
                  addedItems.splice(index, 1);
                }
              }"
            >
              <feather-icon
                icon="TrashIcon"
                stroke="red"
                class="mx-0 clickable danger"
                :id="`invoice-row-${item.id}-delete-icon`"
              />
            </b-button>
          </div>
        </template>
        <template
          #foot()
        >
          <span>
          </span>
        </template>
      </b-table>
      </b-card>
  </template>
  <script>
  export default {
    emits: ['update:items'],
    props: {
      items: {
        type: [Array, Function],
        default: () => [],
      },
      storeId: {
        type: [String, Number, null],
        required: false,
        default: null,
      },
       categoryId: {
        type: [String, Number, null],
        required: false,
        default: null,
      },
    },
    data() {
      return {
        isTableBusy: false,
        isDisabled: true,
        isSortDirDesc: false,
        perPage: 25,
        searchQuery: '',
        sortDirection: 'asc',
        currentPage: 1,
        sortBy: '',
        filterOn: [],
        addedItems: [],
        currentPage2: 1,
        perPage2: 25,
        totalRows: 0,
        filter: {
          barcode: '',
          arabicNamePart: '',
          englishNamePart: '',
          OrderClause: '',
          categoryId: null,
          storeId: null
        },
        error: {},
      };
    },
    computed: {
       tableColumns() {
        return [
          {
            key: 'barcode',
            label: this.$t('barcode'),
            sortable: true,
            thStyle: {
              width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '4%' : '10%'
            },
            formatter: (value, key, item) => {
              const unit = item.units.find((u) => u.unitId === item.unitId);
               item.barCode = unit.barcode;
                return item.barCode;
           },
          },
          {
            key: this.isRight ? 'arabicName' : 'englishName',
            label: this.$t('name'),
            sortable: true,
            thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '35%' : '60%' },
          },
          {
            key: 'mainUnitId',
            label: this.$t('unit'),
            sortable: true,
            thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '10%' : '20%' },
          },
          {
            key: this.isRight ? 'categoryArabicName' : 'categoryEnglishName',
            label: this.$t('category'),
            sortable: true,
            thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '10%' : '20%' },
          },
          {
            key: 'actions',
            thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '10%' : '30%' },
          },
        ];
      },
      columns() {
        return [
          {
            key: 'barcode',
            label: this.$t('barcode'),
            sortable: true,
            thStyle: {
              width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '4%' : '10%'
            },
            formatter: (value, key, item) => {
              const unit = item.units.find((u) => u.unitId === item.unitId);
               item.barCode = unit.barcode;
                return item.barCode;
           },
          },
          {
            key: this.isRight ? 'arabicName' : 'englishName',
            label: this.$t('name'),
            sortable: true,
            thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '30%' : '60%' },
          },
          {
            key: 'mainUnitId',
            label: this.$t('unit'),
            sortable: true,
            thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '10%' : '20%' },
          },
          {
            key: this.isRight ? 'categoryArabicName' : 'categoryEnglishName',
            label: this.$t('category'),
            sortable: true,
            thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '10%' : '20%' },
          },
          {
            key: 'actions',
            thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '10%' : '30%' },
          },
        ];
      }
    },
    methods: {
       onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
       triggerAdding(item) {
          const existItem = this.addedItems.find((val) => val.id === item.id && val.unitId === item.unitId);
          if (!existItem) {
            this.addedItems.push(item);
            this.$emit('update:items', this.addedItems);
          }
      },
       itemsSearchProvider(ctx, callback) {
        const {
          currentPage, perPage, sortBy, sortDesc
        } = ctx;
        this.filter.categoryId = this.categoryId || null;
        this.filter.storeId = this.storeId || null;
        this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
        let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;
        params += this.getFilterObj(this.filter);
        this.isTableBusy = true;
        this.get({ url: `items${params}` })
          .then(({ data, totalCount }) => {
            this.isTableBusy = false;
            this.totalRows = totalCount === 0 ? 1 : totalCount;
            data.filter((item) => {
              item.quantity = 0;
              return false;
            });
            data.forEach((item) => {
              const store = item.stores.find((val) => val.storeId === this.storeId) || { currentBalance: 0, costprice: 0 };
              item.currentBalance = store.currentBalance;
              item.costPrice = store.costPrice;
              item.units = item.subUnits.map((unit) => {
                return {
                  ...unit,
                  itemId: item.id,
                  costPrice: store.costPrice * unit.conversionRate,
                };
              });
              item.unitId = item.mainUnitId;
              item.barCode = item.matchedUnitId ? item.units.find((u) => u.unitId === item.matchedUnitId).barcode : item.mainUnitBarcode;
              item.units.push({
                unitId: item.mainUnitId,
                unitArabicName: item.mainUnitArabicName,
                unitEnglishName: item.mainUnitEnglishName,
                salesPrice: item.salesPrice,
                minSalesPrice: item.minSalesPrice,
                maxSalesPrice: item.maxSalesPrice,
                barcode: item.mainUnitBarcode,
                conversionRate: 1,
                itemId: item.id,
                costPrice: store.costPrice,
                isMain: true,
              });
            });
            callback(data);
          })
          .catch(() => {
            this.isTableBusy = false;
            callback([]);
          });
        return null;
      },
      refreshTable() {
         this.$refs.itemstable.refresh();
      },
       refreshItems() {
        this.refreshTable();
      },
       closeModal() {
        this.$refs['search-modal'].hide();
        this.filter = {
          code: '',
          arabicNamePart: '',
          englishNamePart: '',
          OrderClause: '',
        }
      },
        openModal() {
        // if (!this.storeId) {
        //   this.doneAlert({ text: this.$t('selectStore'), type: 'warning' });
        //    return
        // }
        this.$refs['search-modal'].show();
      },
    },
  };
  </script>
  <style lang="scss">
    .nameTd {
      white-space: nowrap !important;
    }
  </style>
